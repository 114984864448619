@media (min-width: 1101px){
    .main-content.message-chat-box{
        min-height: calc(100vh - 8rem);
        margin-block-end: 0;
        margin-block-start: 1rem;
        margin-inline-start: 15%;
        margin-inline-end: 0;
        transition: all 0.05s ease;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1100px){
    .main-content.message-chat-box {
        min-height: calc(100vh - 8rem);
        margin-block-end: 0;
        margin-block-start: 1rem;
        margin-inline-start: 20%;
        transition: all 0.05s ease;
    }
}
@media only screen and (max-width: 991px) {
    .main-content.message-chat-box .home {
        margin-top: 3rem !important;
        padding-bottom: 0px !important;
        margin-bottom: 10px !important;
    }
}
.message-chat-box .conversations-box {
    background: #fff;
    padding: 1.5rem 0px 0px;
    height: 100%;
    border-right: 1px solid #eee;
}
.message-chat-box .conversations-box .message-left-header {
    padding: 0px 1.25rem;
    display: flex;
}
.message-chat-box .meassge-start .conversations-box .message-left-header h1{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 0px;
}
.message-chat-box .meassge-start .conversations-box  .search-bar {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 8px;
    padding: 8px 13px 8px 5px;
    margin: 1rem 1.25rem;
}

.message-chat-box .meassge-start .conversations-box  .search-bar input {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
    color: #1c1c1c;
    font-weight: 400;
}
.message-chat-box .meassge-start .conversations-box  .search-bar input::placeholder {
    font-size: 14px;
    color: #7685A3;
    font-weight: 400;
}
.message-chat-box .meassge-start .conversations-box  .search-bar img{
    width: 16px;
    height: 16px;
}
.message-chat-box .meassge-start .conversations-box .chat-user .chat-user-left img{
    width: 48px;
    height: 48px;
}
.message-chat-box .meassge-start .conversations-box .chat-user .chat-user-left h3{
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0px;
}
.message-chat-box .meassge-start .conversations-box .chat-user .chat-user-left p{
    font-size: 12px;
    font-weight: 400;
    color: #646464;
    margin-bottom: 0px;
}
.message-chat-box .meassge-start .conversations-box .chat-user .chat-user-left .time-chat{
    text-align: -webkit-right;
}
.message-chat-box .meassge-start .conversations-box .chat-user .chat-user-left .time-chat h6{
    font-size: 12px;
    font-weight: 400;
    color: #646464;
    margin-bottom: 4px;
    white-space: nowrap;
}
.message-chat-box .meassge-start .conversations-box .chat-user .chat-user-left .time-chat p{
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background-color: #1D4D86;
    border-radius: 4px;
    width: 19px;
    height: 19px;
    padding: 1px 6px;
}
.message-chat-box .meassge-start .conversations-box .chat-user{
    margin: 16px 1.25rem 0px;
    border-bottom: 1px solid #E6E7E9;
    cursor: pointer;
    padding-bottom: 12px;
}
.message-chat-box .meassge-start .conversations-box .chat-user.active{
    background-color: #E5E9EF;
    border-right: 3px solid #083C72;
    margin: 0;
    padding: 12px 1.25rem !important;
}
/* width */
.multiple-chat::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
.multiple-chat::-webkit-scrollbar-track {
    background: #e7ecf0; 
  }
   
  /* Handle */
.multiple-chat::-webkit-scrollbar-thumb {
    background: #aaa; 
  }
  
  /* Handle on hover */
.multiple-chat::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
@media only screen and (min-width:575px) and (max-width:767px){
    .message-left-header{
        display: block !important;
    }
}

.multiple-chat{
    padding-bottom: 10px;
}
@media only screen and (min-width:575px) and (max-width:991px){
    .multiple-chat {
        position: fixed;
        height: calc(100vh - 175px);
        height: -webkit-fill-available;
        overflow-y: auto;
        width: 40.7vw;
        padding-right: 1px;
    }
}
@media only screen and (min-width:992px) and (max-width:1101px){
    .multiple-chat {
        position: fixed;
        height: calc(100vh - 175px);
        height: -webkit-fill-available;
        overflow-y: auto;
        width: 33vw;
        padding-right: 1px;
    }
}
@media only screen and (min-width:1102px) and (max-width:1199px){
    .multiple-chat {
        position: fixed;
        height: calc(100vh - 175px);
        height: -webkit-fill-available;
        overflow-y: auto;
        width: 35.4vw;
        padding-right: 1px;
    }
}
@media (min-width:1200px){
    .multiple-chat {
        position: fixed;
        height: calc(100vh - 175px);
        height: -webkit-fill-available;
        overflow-y: auto;
        width: 28.4vw;
        padding-right: 1px;
    }
}

.message-chat-box .chat-start .chat-start-header{
    background-color: #fff;
    padding: 20px 20px 16px;
    border-bottom: 1px solid #EAEAEA;
}
.message-chat-box .chat-start .chat-start-header img{
    width: 48px;
    height: 48px;
}
.message-chat-box .chat-start .chat-start-header h3{
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0px;
}
.message-chat-box .chat-start .chat-start-header span{
    font-size: 12px;
    font-weight: 500;
   color: #5BA559;
   margin-bottom: 0px;
}
.message-chat-box .chat-start .chat-start-header img.more-icon{
    width: 24px !important;
    height: 24px !important;
}
.message-chat-box .chat-start .main-chat-box {
    height: -webkit-fill-available;
    position: fixed;
    overflow-y: auto;
    width: -webkit-fill-available;
    background: #f9f9f9;
    padding:  .5rem 1.25rem 1rem 1.25rem;
}
.message-chat-box .chat-start .main-chat-box {
    height: calc(100vh - 205px);
    width: -moz-available;
}
.message-chat-box .chat-start .msg-box img{
    width: 38px;
    height: 38px;
}
.message-chat-box .chat-start .msg-box-reply img{
    width: 38px;
    height: 38px;
}

.message-chat-box .chat-start .msg-box{
    height: auto;
    margin-bottom: .5rem;
}
.message-chat-box .chat-start .msg-box-reply {
    display: grid;
    height: auto;
    justify-content: flex-end;
    align-items: flex-start;
}
.message-chat-box .chat-start .msg-box p{
    background-color: #E5E9EF;
    border-radius: 0px 10px 10px 10px;
    height: auto;
    padding: .6rem .5rem;
    font-size: 14px;
    font-weight: 500;
    color: #3C3C3C;
    margin-bottom: 10px !important;
    width: fit-content;
}
.message-chat-box .chat-start .msg-box-reply p{
    background-color: #E5E9EF;
    border-radius: 10px 0px 10px 10px;
    height: auto;
    padding: .6rem .5rem;
    font-size: 14px;
    font-weight: 500;
    color: #3C3C3C;
    margin-bottom: 10px !important;
    width: fit-content;
}
.message-chat-box .chat-start .msg-box span{
    font-size: 12px;
    font-weight: 500;
    color: #6F6F6F;
    position: relative;
    top: -6px;
}
.message-chat-box .chat-start .msg-box-reply span{
    font-size: 12px;
    font-weight: 500;
    color: #6F6F6F;
    position: relative;
    top: -6px;
}
/* width */
.message-chat-box .chat-start .main-chat-box::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .message-chat-box .chat-start .main-chat-box::-webkit-scrollbar-track {
    background: #e7ecf0; 
  }
   
  /* Handle */
  .message-chat-box .chat-start .main-chat-box::-webkit-scrollbar-thumb {
    background: #aaa; 
  }
  
  /* Handle on hover */
  .message-chat-box .chat-start .main-chat-box::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.chat-footer {
    position: fixed;
    bottom: 0px;
    background: #fff;
    width: -webkit-fill-available;
    padding: 1rem 1.25rem;
}
.chat-footer {
    width: -moz-available !important;
}
.chat-footer input {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
    color: #5B5B5B;
    font-weight: 500;
    padding:  .5rem 1.25rem;
    width: 100%;
}
.chat-footer .chat-footer-write {
    display: flex;
    align-items: center;
    background-color: #F3F3F4;
    border-radius: 8px;
    padding: 0px 10px 0px 0px;
    
}
.chat-footer .chat-footer-write{
    cursor: pointer;
}
.chat-footer .chat-footer-write img{
     width: 20px;
     height: 20px;
}
.chat-footer .chat-footer-write button{
    border: none;
    background: transparent;
}
.chat-footer .chat-footer-write button.btn-send-msg img{
    width: 43px;
    height: 43px;
}
