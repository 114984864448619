.add-bill-popup{

}
.add-bill-popup .modal-header{
    border-bottom: none;
    padding-bottom: 0px;
}
.add-bill-popup .modal-content{
    padding-block: 20px;
}
.add-bill-popup .add-bill-btn {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
}
.add-bill-popup .add-bill-btn .save-btn{
    border: 0;
    padding: .5rem;
    border-radius: 50px;
    min-width: 100%;
    background-color: #003366;
    color: #fff;
}
.add-bill-popup .add-bill-btn .cancel-btn{
    border: 1px solid #003366;
    color: #003366;
    padding: .5rem;
    border-radius: 50px;
    min-width: 100%;
    background-color: transparent;
}
.add-bill-popup .form-control:focus {
    box-shadow: none !important;
}
.add-bill-popup label.form-label{
    font-size: 14px;
    font-weight: 500;
    color: #0A0A0A;
}
.add-bill-popup input.form-control{
    font-size: 16px;
    font-weight: 500;
    color: #525252;
}
.add-bill-popup input.form-control::placeholder{
    font-size: 16px;
    font-weight: 500;
    color: #525252;
}
.add-bill-popup .form-control:disabled {
    background-color: #E5E7EB;
    opacity: 1;
}
.add-bill-popup input#BillNo::placeholder {
    color: #A3A3A3 !important;
}
.add-bill-popup .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem #003366a6;
    opacity: var(--bs-btn-close-focus-opacity);
}
.add-bill-popup button.btn-close {
    font-size: 12px;
}
.add-bill-popup .modal-title.h4 {
    font-size: 18px;
    font-weight: 500;
    color: #1C1C1C;
}
@media (min-width: 576px) {
    .add-bill-popup .modal-dialog {
        max-width: 628px;
        margin-right: auto;
        margin-left: auto;
    }
    .add-bill-popup .add-bill-btn {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1rem;
    }
    .add-bill-popup .add-bill-btn .save-btn{
        border: 0;
        padding: .5rem;
        border-radius: 50px;
        min-width: 120px;
        background-color: #003366;
        color: #fff;
    }
    .add-bill-popup .add-bill-btn .cancel-btn{
        border: 1px solid #003366;
        color: #003366;
        padding: .5rem;
        border-radius: 50px;
        min-width: 120px;
        background-color: transparent;
    }
}