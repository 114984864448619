/* pateint-details-popup code start */
.doctor-medication-manage-popup .modal-dialog {
    max-width: 819px;
}
.doctor-medication-manage-popup .modal-header {
    margin: 20px 24px 10px;
    border: 0;
}
.doctor-medication-manage-popup .modal-header h3{
    color: #1C1C1C;
    font-size: 18px;
    font-weight: 600;
}

.doctor-medication-manage-popup  .doctor-medication-manage-popup-body{
    padding: 8px 24px 10px;

}
.doctor-medication-manage-popup .doctor-medication-manage-popup-body button.btn-close {
    margin-right: .5rem !important;
}
.doctor-medication-manage-popup  .pateint-details-card {
    display: grid;
    gap: 1rem;
}
.doctor-medication-manage-popup  .popup-header{
    border-bottom: 1.5px solid #E6E7E9;
    padding: 0px  0px 1.25rem;
}
.doctor-medication-manage-popup .modal-body{
    padding: 0px;
}
.doctor-medication-manage-popup  .icon-name {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 2rem;
    align-items: center;
}
.doctor-medication-manage-popup  .pateint-details-card img {
    width: 60px;
    height: 60px;
}
.doctor-medication-manage-popup  img.online-dot {
    width: 10px;
    position: absolute;
    bottom: -20px;
    right: -15px;
}
.doctor-medication-manage-popup  .pateint-name-popup h5{
    font-size: 20px;
    font-weight: 600;
    color: #111111;
    margin-bottom: 0px;
}
.doctor-medication-manage-popup  .pateint-name-popup p{
    font-size: 16px;
    font-weight: 500;
    color: #6F6F6F;
    margin-bottom: 0px;
}
.doctor-medication-manage-popup  .Patient-more {
    display: grid;
    gap: .4rem;
}
.doctor-medication-manage-popup  .Patient-more div{
    display: grid;
    gap: 0rem;
    /* grid-template-columns: 75px 1fr; */
    align-items: center;
}
.doctor-medication-manage-popup  .Patient-more div p{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
}
.Patient-more div p span{
    color: #1C1C1C;
    font-size: 18px;
    font-weight: 400;
}
.doctor-medication-manage-popup .popup-report-box .resident {
    box-shadow: none;
    margin-bottom: 0px;
    padding: 8px 0px;
    border-radius: 0px;
}
/* .doctor-medication-manage-popup .popup-report-box .resident:nth-child(1){
    border-bottom: 1px solid #E6E7E9;
} */
.doctor-medication-manage-popup .popup-report-box .resident table tbody tr td{
    text-align: left;
    padding: .8rem .8rem;
}
.doctor-medication-manage-popup .popup-report-box .resident .checkbox input {
    margin-right: 20px;
}


@media(max-width:450px){
    .doctor-medication-manage-popup .popup-report-box .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .doctor-medication-manage-popup .popup-report-box .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
    .doctor-medication-manage-popup .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .doctor-medication-manage-popup .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
}


/* pateint-details-popup code end */

.doctor-medication-manage-popup .resident {
    box-shadow: 0px 4px 40px 0px #0000000D;
    margin-bottom: 0px;
    padding: 21px 16px;
    border-radius: 10px;
    /* border: .5px solid #f7f7f7; */
}
.doctor-medication-manage-popup .resident thead.table tr th {
    font-weight: 500;
    font-size: 14px;
}
.doctor-medication-manage-popup .resident thead.table {
    background: #00234F;
    color: #fff;
}
.doctor-medication-manage-popup .resident .table-responsive {
    border-radius: 8px;
}
.doctor-medication-manage-popup .resident table th , .resident table td, .resident table tr{
    border: none;
}

.doctor-medication-manage-popup .resident .table th, .table td {
    vertical-align: middle;
    text-align: left;
}

.doctor-medication-manage-popup .resident .table th i{
    cursor: pointer;
    color: #7685A3;
}

.doctor-medication-manage-popup .resident .filtericon button {
    border: none;
    border-radius: 12px;
}
.doctor-medication-manage-popup .resident .left-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.doctor-medication-manage-popup .resident .text-success {
    color: #5BA559;
    font-weight: 600;
}
.doctor-medication-manage-popup button.btn.btn-sm {
    padding: 0px 2px;
}
.doctor-medication-manage-popup .resident .text-danger{
    color: #E50000;
    font-weight: 600;
}

.doctor-medication-manage-popup .resident .text-warning {
    color: #E17C00;
    font-weight: 600;
}

.doctor-medication-manage-popup .resident h5 {
    font-size: 16px;
    font-weight: 600;
}
.doctor-medication-manage-popup .checkbox i.bi.bi-arrow-down-up {
    margin-left: 5px;
    font-size: 10px;
}
.doctor-medication-manage-popup .resident thead.table tr th i {
    font-size: 11px;
}
.doctor-medication-manage-popup .resident .left-content .input-field input.form-control {
    padding: 11px 13px;
    border: none;
    background: #F3F3F4;
    color: #7685A3;
    border-radius: 10px;
}
.doctor-medication-manage-popup .resident .left-content .input-field input.form-control::placeholder{
    color: #7685A3;
}
.doctor-medication-manage-popup .resident button.btn.btn-primary {
    background-color: #003366;
    color: #fff;
    font-size: 14px;
    padding: 13px 19px;
    border-radius: 50px;
    border: 0;
}
.doctor-medication-manage-popup .resident thead.table {
    background: #00234F;
    color: #fff;
}
.doctor-medication-manage-popup .updown-arrow {
    width: 12px;
    height: 12px;
    fill: #7685A3;
    margin-inline: .3rem;
    padding: 0px !important;
    background-color: transparent !important;
    color: transparent;
}
.doctor-medication-manage-popup .table thead th {
    background-color: #00234F;
    color: white;
    text-align: left;
    padding: .8rem .8rem;
}
.doctor-medication-manage-popup .checkbox {
    padding: .5rem 0px !important;
    background-color: transparent !important;
}
.doctor-medication-manage-popup .checbox-trans input[type=checkbox] {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-right: 5px;
    margin-top: -1px;
}
.doctor-medication-manage-popup .table-stripedd>tbody>tr:nth-of-type(even)>td {
    background-color: #F6F6F6 !important;
}
.doctor-medication-manage-popup .table-stripedd>tbody>tr:nth-of-type(odd)>td {
    background-color: #FFFFFF !important;
}

.doctor-medication-manage-popup .resident .table-responsive{
    position: relative;
}
.doctor-medication-manage-popup .resident ul.edit-rt{
    border: 1px solid #fdfdfd;
}
.doctor-medication-manage-popup .borderbd{
    border-bottom: 1px dashed #f7f7f7;
}
.doctor-medication-manage-popup .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    border-color: transparent !important;
}
/* Responsive css */

@media only screen and (max-width: 991px) {
    .doctor-medication-manage-popup .home {
        margin-top: 66px;
    }

}
/* .d-grid.add-resident-box {
    grid-template-columns: auto auto;
    gap: 1rem;
    align-items: center;
    
} */
.doctor-medication-manage-popup .btn-resident button{
    display: flex;
    align-items: center;
    gap: 3px;
 }
 .doctor-medication-manage-popup .btn-resident button i{
    margin-right: 2px;
 }
 .doctor-medication-manage-popup .new-message i{
    margin-right: 3px;
 }
 .doctor-medication-manage-popup .resident .left-content .input-field.position-relative span img {
    position: absolute;
    width: 15px;
    top: 15px;
    right: 1rem;
}
.doctor-medication-manage-popup .resident span.paid {
    background: #5BA55933;
    color: #2B5629;
    padding: 6px 0px;
    border-radius: 50px;
    width: 120px;
    display: block;
    margin: 0 auto;
}
.doctor-medication-manage-popup .resident span.unpaid {
    background: #E500001A;
    color: #EC0000;
    padding: 6px 0px;
    border-radius: 50px;
    width: 120px;
    display: block;
    margin: 0 auto;
}
@media(max-width:450px){
    .doctor-medication-manage-popup .popup-report-box .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .doctor-medication-manage-popup .popup-report-box .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
    .doctor-medication-manage-popup .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .doctor-medication-manage-popup .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
}
