 .calendar .month {
    width: 90%;
    text-align: center;
    margin: auto;
}

.calendar .month ul {
    margin: 0;
    padding: 0;
}

.calendar .month ul li {
    color: #000;
    font-size: 13px;
    letter-spacing: 3px;
    font-weight: 500;
}

.calendar .month .prev {
    float: left;
}

.calendar .month .next {
    float: right;
}

.calendar .weekdays {
    margin: 0;
    padding: 6px 0px;
    width: 100%;
}

.calendar .weekdays li {
    display: inline-block;
    width: 12%;
    color: #666;
    text-align: center;
    font-size: 12px;
}

.calendar .days {
    padding: 6px 0px;
    margin: 0;
}

.calendar .days li {
    list-style-type: none;
    display: inline-block;
    width: 12%;
    text-align: center;
    margin-bottom: 26px;
    font-size: 14px;
    font-weight: 500;
    color: #111;
}

.calendar .days li .active {
    padding: 6px;
    background: #1D4D86;
    color: white !important;
    border-radius: 50%;
}
.calendar {
    width: 100%;
    box-shadow: 0px 4px 40px 0px #0000000D;
    padding: 10px 10px;
    border-radius: 12px;
    /* height: 100%; */
}
/* Calendr code start */
.calendar.ds-cd button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
}
.calendar.ds-cd button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
    display: none;
}
.calendar.ds-cd .react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    border: none;
    font-family:'Onest', sans-serif;
    line-height: 1.125em;
    margin-inline: auto;
}
.calendar.ds-cd abbr[title]{
    text-decoration: none;
    color: var(--textgrey);
    font-size: 14px;
    font-weight: 400;

}
.calendar.ds-cd button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--textblack);
    font-size: 14px;
    font-weight: 400;
}
.calendar.ds-cd button.react-calendar__tile.react-calendar__month-view__days__day{
    color: var(--textblack);
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0px !important;
    background: transparent;
}
.calendar.ds-cd button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day abbr{
    background-color: #1D4D86;
    border-radius: 100%;
    padding: 12px 0px;
    display: grid;
    justify-content: center;
    align-items: center;
}
.calendar.ds-cd button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day abbr{
    color: #fff;
}
.calendar.ds-cd .react-calendar__tile--active abbr {
    background: var(--btnblue) !important;
    color: white !important;
    border-radius: 100%;
    padding: 12px 0px;
    display: grid;
    justify-content: center;
    align-items: center;
}
.calendar.ds-cd button.react-calendar__navigation__label {
    font-size: 16px;
    font-weight: 600;
}
.calendar.ds-cd button.react-calendar__navigation__arrow {
    font-size: 40px;
    color: var(--themeblue);
}
.calendar.ds-cd .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: transparent !important;
}
@media only screen and (max-width: 991px) {
    .family-home .calendar .days li {
        margin-bottom: 20px;
    }
}
/* Calendr code end */