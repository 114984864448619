.notes-main{
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000D;
    padding: 20px 16px 6px;
    border-radius: 12px;
}
.notes-main .notes-top h3{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
}
.notes-main .notes-top a{
    font-size: 14px;
    font-weight: 500;
    color: #003366;
}
.notes-main .notes-top button{
    background-color: #003366;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 100px;
    padding: 8px 15px;
    outline: none;
    border: none;

}
.notes-main .note-info{
    background-color: #F9F9F9;
    padding: 12px 10px;
    border-radius: 10px;
    margin-block-start: 20px;
    margin-block-end: 14px;
}
.notes-main .note-info h3{
    color: #000;
    font-size: 16px;
    font-weight: 600;
}
p{
    font-size: 12px;
    font-weight: 400;
    color: #5B5B5B;
}