.aside-bar {
    background-color: #00234F;
    border-right: 1px solid #f8f8f8;
}

.sarch-bar i.fa-light.fa-magnifying-glass {
    position: absolute;
    left: 8px;
    font-size: 11px;
}
.mt_20{
    margin-top: 20px;
}
.aside-bar-menu {
    width: 100%;
}
.logo a.navbar-brand img {
    width: 27%;
    margin-bottom: 9px;
    margin-left: 20px;
}

.aside-bar-menu ul .nav-item a.nav-link img {
    width: 18px;
    margin-right: 5px;
    height: 18px;
}

.aside-toggle i.fa-solid.fa-angle-left {
    position: absolute;
    width: 24px;
    height: 25px;
    background: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    right: -7px;
    border: 1px solid #ccc;
    bottom: 10%;
    z-index: 99;
}


.aside-bar-menu ul .nav-item {
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 10px;
    padding: 12px 0px 12px 7px;
}

.logout-button {
    margin-top: 130px !important;
}

.aside-bar-menu ul .nav-item.active {
    background-color: #fff;
}

.aside-bar-menu ul .nav-item.active a.navlik {
    color: #00234F !important;
}

.aside-bar-menu ul .nav-item a img.color-icon {
    display: none;
}

.aside-bar-menu ul .nav-item.active a.nav-link img.white-icon {
    display: none;
}

.aside-bar-menu ul .nav-item.active a.nav-link img.color-icon {
    display: inline-block;
}

.aside-bar-menu ul .nav-item a.nav-link {
    color: #FFF !important;
    font-size: 14px;
}
.aside-bar-menu ul .nav-item a{
    color: #FFF !important;
    font-size: 14px;
}

.aside-bar-menu .nav-item.active a.nav-link {
    color: #00234F  !important;
}

/* .dashboard-two {
    width: 85%;
    position: relative;
} */

.top-bar {
    width: 100%;
    position: fixed;
    background-color: #fff;
    padding: 7px 20px;
    border-bottom: 2px solid #EAEAEA;
    z-index: 8;
    top: 0;
}

.top-bar-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sarch-bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


.sarch-bar input {
    padding: 7px 26px;
    min-width: 275px;
    border-radius: 20px;
    background: #E3E3E3;
    outline: none;
    border: none;
    color: #000;
    font-size: 12px;
    font-weight: 300;
    margin-right: 19px;
}

.sarch-bar input::placeholder {
    color: #000;
}

.sarch-bar i {
    color: #1C1C1C;
}

.login-profile {
    display: flex;
    align-items: center;
    float: right;
    width: auto;
    height: 100%;
}
.top-bar-search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.login-profile .Profile-image img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.login-profile .profile-details p {
    color: #1C1C1C;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: -5px;
}

.login-profile .profile-details span {
    color: #1C1C1C;
    font-size: 10px;
}

.bell-icon {
    position: relative;
    border-right: 2px solid #9c9a9a;
    padding-right: 18px;
    margin-right: 27px;
}

.sign-up .container {
    width: 1440px;
}

.bell-icon span {
    position: absolute;
    background: #FF8A00;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.bell-icon i.fa-regular.fa-bell {
    font-size: 20px;
}


.navbar>.container-fluid{
    display: inline;
    padding: 0px;
}
.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    display: block;
    margin: 0px !important;
    width: 100%;
}

.mobile-menu .navbar-collapse{
    background-color: #00234F;
    position: absolute;
}
/* .navbar-expand-lg .navbar-collapse {
    display: block !important;
} */
.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px;
}
@media only screen and (max-width: 991px) {
    .tab-menu{
        display: none;
    }
    .mobile-menu{
        display: block !important;
    }
    .aside-bar{
        background-color: transparent;
    }
    .dashboard-two {
        width: 100%;
    }
    .top-bar {
        width: 100%;
    }
    .aside-bar {
        width: 100%;
    }
    .mobile-menu button.navbar-toggler i {
        font-size: 22px;
    }
    .mobile-menu button.navbar-toggler {
        border: 1px solid black;
        padding: 7px 13px;
    }
    .mobile-menu .navbar-collapse {
        background-color: #00234F;
        position: absolute;
        left: -59px;
        left: -20px;
        width: 200px;
        top: 65px;
    }
    .top-bar-inner {
        justify-content: space-between;
    }
}
@media only screen and (max-width: 600px){
    .top-bar {
        padding-right: 20px;
        padding-left: 20px;
    }
    .sarch-bar {
        display: none;
    }
    .mobile-menu .navbar-collapse {
        left: -17px;
        top: 65px;
    }
}

.tab-menu .aside-bar{
    width: 15%;
    background: #00234F;
    border-inline-end: 1px solid var(--menu-border-color);
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: 103;
    transition: all 0.05s ease;
    /* height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden; */
}
@media only screen and (min-width: 992px) and (max-width:1100px){
    .tab-menu .aside-bar{
        width: 20%;
        background: #00234F;
        border-inline-end: 1px solid var(--menu-border-color);
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        z-index: 103;
        transition: all 0.05s ease;
        /* height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden; */
    }
}
/* width */
.tab-menu .aside-bar::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  .tab-menu .aside-bar::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .tab-menu .aside-bar::-webkit-scrollbar-thumb {
    background: #f1f1f1; 
  }
  
  /* Handle on hover */
  .tab-menu .aside-bar::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }
  .sarch-bar.position-relative img{
    position: absolute;
    width: 12px;
    top: 10px;
    left: .6rem;
  }
  /* Main Content Css Start */
.main-content{
    overflow-x: hidden;
}
.diet-nutrition-box .home {
    padding: 10px 10px;
    margin-top: 10px;
}
.card-dsgn{
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000F;
    padding: 20px 16px;
    border-radius: 12px;
    /* height: 100%;*/
}
@media (min-width: 1101px) {
    .main-content {
        min-height: calc(100vh - 8rem);
        margin-block-end: 0;
        margin-block-start: 3rem;
        margin-inline-start: 16%;
        margin-inline-end: 1%;
        transition: all 0.05s ease;
    }
}
@media only screen and (min-width: 992px) and (max-width:1100px){
    .main-content {
        min-height: calc(100vh - 8rem);
        margin-block-end: 0;
        margin-block-start: 3.25rem;
        margin-inline-start: 21%;
        transition: all 0.05s ease;
    }
}
@media only screen and (max-width: 991px) {
    .home {
        margin-top: 5rem !important;
    }

}

/* Main Content Css End */
