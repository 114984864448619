.mt_20{
    margin-top: 20px;
}
.diet-nutrition-box .diet-time{
    margin-top: 20px;
}
.diet-nutrition-box .home {
    padding: 10px 10px;
    margin-top: 10px;
}
.diet-nutrition-box .page-header h2{
    font-size: 1.375rem;
    font-weight: 600;
    color: #1C1C1C;
    /* margin-bottom: 20px; */
 }
 .diet-nutrition-box .input-field select {
    padding: 5px 7px;
    border: 1px solid #E6E7E9;
    border-radius: 5px;
}
.diet-nutrition-box .diet-time-one{
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000F;
    padding: 20px 16px;
    border-radius: 12px;
    height: 100%;
}
.diet-nutrition-box .diet-time-one .diet-time-top h3{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 0px;
}
.diet-nutrition-box .diet-time-one .diet-time-top p{
    font-size: 16px;
    font-weight: 500;
    color: #6F6F6F;
    margin-bottom: 0px;
}
.diet-nutrition-box .diet-time-one .diet-time-top button{
    background-color: #003366;
    color: #fff;
    padding: 5px 8px;
    font-size: 10px;
    outline: none;
    border: none;
    border-radius: 100vmax;
}
.diet-nutrition-box .diet-info{
    padding-block: 20px;
    border-bottom: 1px solid #E6E7E9;
}
.diet-nutrition-box .diet-info:last-child{
    padding-block-end: 0px;
    border-bottom: none;
}
.diet-nutrition-box .diet-info .left-diet-info h4{
    font-size: 16px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 0px;
}
.diet-nutrition-box .diet-info .left-diet-info p{
    font-size: 14px;
    font-weight: 500;
    color: #6F6F6F;
    margin-bottom: 0px;
}
.diet-nutrition-box .diet-info .right-diet-info p{
    font-size: 14px;
    font-weight: 500;
    color: #6F6F6F;
    margin-bottom: 0px;
}
.diet-nutrition-box  .diet-reminder{
     background-color: #F9F9F9;
     display: grid;
     align-items: center;
     height: 124px;
     justify-content: center;
     margin-block-start: 20px;
     border-radius: 10px;
}
.diet-nutrition-box .diet-reminder p{
    font-size: 16px;
    font-weight: 500;
    color: #6F6F6F;
    margin-bottom: 0px;
}
.diet-nutrition-box .calorie-card{
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000F;
    padding: 20px 16px;
    border-radius: 12px;
    /* height: 100%; */
}
.diet-nutrition-box .calorie-card h3{
    font-size: 22px;
    font-weight: 600;
    color: #1C1C1C;
    margin-block-end: 20px;

}
.diet-nutrition-box .calorie-meals{
    padding-block-end: 20px;
    border-bottom: 1px solid #E6E7E9;
}
.diet-nutrition-box .calorie-meals img{
    width: 25px;
    height: 25px;
}
.diet-nutrition-box .calorie-meals .CircularProgressbar {
    width: 80px;
    vertical-align: middle;
}
.diet-nutrition-box .calorie-meals .CircularProgressbar .CircularProgressbar-path {
    stroke: #fa9600;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}
.diet-nutrition-box .calorie-meals h4{
     font-size: 18px;
     font-weight: 600;
     color: #1C1C1C;
}
.diet-nutrition-box .calorie-meals p{
    font-size: 14px;
    font-weight: 400;
    color: #6F6F6F;
}
.diet-nutrition-box .diet-breakup h4{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 0px;
}
.diet-nutrition-box .diet-breakup .bean-main .bean-box img{
    width: 20px;
    height: 20px;
}
.diet-nutrition-box .diet-breakup .bean-main .bean-box h5{
    font-size: 16px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 0px;
}
.diet-nutrition-box .diet-breakup .bean-main .progress-box{
    flex: 1;
    display: flex;
    gap: 5px;
    align-items: center;
}
.diet-nutrition-box .diet-breakup .bean-main .progress-box P{
    font-size: 16px;
    font-weight: 500;
    color: #3C3C3C;
}
.diet-nutrition-box .diet-breakup .bean-main .progress-box .progress {
    flex: 1;
    height: 10px;
}
.diet-nutrition-box .diet-breakup .bean-main .progress-box .progress .bg-info {
    --bs-bg-opacity: 1;
    background-color: #FDCB6E !important;
    height: 10px;
}
.diet-nutrition-box .diet-breakup .bean-main {
    display: grid;
}

.diet-nutrition-box .diet-breakup .bean-main .progress-box span.progress-number{
    font-size: 16px;
    font-weight: 500;
    color: #FDCB6E;
}
.diet-nutrition-box .weekly-trends-main{
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000F;
    padding: 20px 16px;
    border-radius: 12px;
    height: 100%;
}
.diet-nutrition-box .weekly-trends-main .weekly-trends-top{
    border-bottom: 1px solid #E6E7E9;
    padding-bottom: 20px;
}
.diet-nutrition-box .weekly-trends-main .weekly-trends-top h3{
    font-size: 22px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 12px;
}
.diet-nutrition-box .weekly-trends-main .weekly-trends-top p{
    font-size: 14px;
    font-weight: 400;
    color: #6F6F6F;
}
.diet-nutrition-box .weekly-trends-main .weekly-trends-chart h4{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
}
.diet-nutrition-box .weekly-trends-main .cal-box h5{
    font-size: 20px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 6px;
}
.diet-nutrition-box .weekly-trends-main .cal-box p{
    font-size: 14px;
    font-weight: 500;
    color: #979797;
}
/* chart box start */
.diet-nutrition-box .weekly-trends-main .chart-box{
    height: fit-content;
    padding: 0px;
 
 }
.diet-nutrition-box .weekly-trends-main .chart-box .chart-box-top h3{
     font-size: 18px;
     font-weight: 600;
 }
.diet-nutrition-box .weekly-trends-main .chart-box .chart-box-top{
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     padding: 20px 20px 10px;
 }
.diet-nutrition-box .weekly-trends-main .chart-box .chart-box-top .chart-options-box .input-field select{
     padding: 5px 7px;
     border: 1px solid #E6E7E9;
     border-radius: 5px;
 }
.diet-nutrition-box .weekly-trends-main .chart-box .css-10vg0a6-MuiResponsiveChart-container{
     width: 100% !important;
     height: 100% !important;
 }
 .chart-box .MuiBar-root {
    width: 10px !important; /* Set the desired bar width */
  }
 /*.diet-nutrition-box .weekly-trends-main .css-yrgrvu{
     fill: #FAA600 !important;
  }
.diet-nutrition-box .weekly-trends-main .css-1qsjhi9{
     fill: #FEDFA8 !important;
  }
 .diet-nutrition-box .weekly-trends-main .chart-box .css-1qdzy9k-MuiBarElement-root {
     fill: #FAA600 !important;
 }
.diet-nutrition-box .weekly-trends-main .MuiChartsLegend-series:nth-child(1) rect.MuiChartsLegend-mark {
     fill: #FAA600 !important;
 }
.diet-nutrition-box .weekly-trends-main .MuiChartsLegend-series:nth-child(2) rect.MuiChartsLegend-mark{
     fill: #FEDFA8 !important;
 }
.diet-nutrition-box .weekly-trends-main .chart-box .css-3dcfwk-MuiBarElement-root {
     fill: #FEDFA8 !important;
 } */
 /* chart box end */

@media only screen and (max-width: 991px) {
    .diet-nutrition-box .home {
        margin-top: 5rem !important;
    }

}
@media only screen and (min-width: 575px) {
    .diet-nutrition-box .diet-breakup .bean-main {
        display: grid;
        grid-template-columns: 140px 1fr;
    }

}
