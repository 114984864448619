.pending-status{
    background-color: #FFF3DC;
    color: #ED6800;
    padding: .3rem 1.25em;
    border-radius: 100vmax;
    font-size: 1rem;
    font-weight: 500;
}
.ordered-status{
    background-color: #E9F3EA;
    color: #3D743B;
    padding: .3rem 1.25rem;
    border-radius: 100vmax;
    font-size: 1rem;
    font-weight: 500;
}