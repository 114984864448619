.patient-health-box .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
}
.patient-health-box .swiper-button-prev{
    color: #fff;
    background: #003366;
    height: 35px;
    border-radius: 39px;
    width: 35px;
}
.patient-health-box .swiper-button-next {
    color: #fff;
    background: #003366;
    height: 35px;
    border-radius: 39px;
    width: 35px;
}
.patient-health-box .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 16px;
}
.patient-health-box .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 16px;
}
.patient-health-box .silde-card {
    background: #fff;
    /* box-shadow: 0px 4px 40px 0px #0000000F; */
    box-shadow: 2px 5px 10px 0px #0000000F;
    padding: 16px 12px;
    border-radius: 12px;
    margin-block-end: 20px;
}
.patient-health-box .silde-card .slide-card-box img{
    width: 40px;
    height: 40px;
}
.patient-health-box .silde-card .slide-card-box h4{
    color: #5B5B5B;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}
.patient-health-box .silde-card .slide-card-box p{
    color: #1C1C1C;
    font-size: 20px;
    font-weight: 600;
    margin-top: 6px;
    margin-bottom: 0px;
}
.patient-health-box .silde-card .slide-card-box span{
    color: #5B5B5B;
    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;
    margin-bottom: 0px;
    margin-left: 5px;
}