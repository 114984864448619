.invoice-box .resident {
    box-shadow: 0px 4px 40px 0px #0000000D;
    margin-bottom: 0px;
    padding: 21px 16px;
    border-radius: 10px;
    border: .5px solid #f7f7f7;
}
.invoice-box .resident thead.table tr th {
    font-weight: 500;
    font-size: 14px;
    text-align: start;
}
.invoice-box .resident thead.table {
    background: #00234F;
    color: #fff;
}
.invoice-box .resident .table-responsive {
    border-radius: 8px;
}
.invoice-box .resident table th , .resident table td, .resident table tr{
    border: none;
}

.invoice-box .resident .table th, .table td {
    vertical-align: middle;
    text-align: center;
}

.invoice-box .resident .table th i{
    cursor: pointer;
    color: #7685A3;
}

.invoice-box .resident .filtericon button {
    border: none;
    border-radius: 12px;
}
.invoice-box .resident .left-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.invoice-box .resident .text-success {
    color: #5BA559;
    font-weight: 600;
}
.invoice-box button.btn.btn-sm {
    padding: 0px 2px;
}
.invoice-box .resident .text-danger{
    color: #E50000;
    font-weight: 600;
}

.invoice-box .resident .text-warning {
    color: #E17C00;
    font-weight: 600;
}

.invoice-box .resident h5 {
    font-size: 16px;
    font-weight: 600;
}
.invoice-box .checkbox i.bi.bi-arrow-down-up {
    margin-left: 5px;
    font-size: 10px;
}
.invoice-box .resident thead.table tr th i {
    font-size: 11px;
}
.invoice-box .resident .left-content .input-field input.form-control {
    padding: 11px 13px;
    border: none;
    background: #F3F3F4;
    color: #7685A3;
    border-radius: 10px;
}
.invoice-box .resident .left-content .input-field input.form-control::placeholder{
    color: #7685A3;
}
.invoice-box .resident button.btn.btn-primary {
    background-color: #003366;
    color: #fff;
    font-size: 14px;
    padding: 13px 19px;
    border-radius: 50px;
    border: 0;
}
.invoice-box .resident thead.table {
    background: #00234F;
    color: #fff;
}
.invoice-box .updown-arrow {
    width: 12px;
    height: 12px;
    fill: #7685A3;
    margin-inline: .3rem;
    padding: 0px !important;
    background-color: transparent !important;
    color: transparent;
}
.invoice-box .table thead th {
    background-color: #00234F;
    color: white;
    text-align: center;
}
.invoice-box .checkbox {
    padding: .5rem 0px !important;
    background-color: transparent !important;
}
.invoice-box .checbox-trans input[type=checkbox] {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-right: 5px;
    margin-top: -1px;
}
.invoice-box .table-stripedd>tbody>tr:nth-of-type(even)>td {
    background-color: #F6F6F6 !important;
}
.invoice-box .table-stripedd>tbody>tr:nth-of-type(odd)>td {
    background-color: #FFFFFF !important;
}

.invoice-box .resident .table-responsive{
    position: relative;
}
.invoice-box .resident ul.edit-rt{
    border: 1px solid #fdfdfd;
}
.invoice-box .borderbd{
    border-bottom: 1px dashed #f7f7f7;
}
.invoice-box .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    border-color: transparent !important;
}
.invoice-box .table-fix {
    display: grid;
    grid-template-columns: 25px 25px 25px;
    align-items: center;
    height: 56px;
}
/* Responsive css */

@media only screen and (max-width: 991px) {
    .invoice-box .home {
        margin-top: 66px;
    }

}
/* .d-grid.add-resident-box {
    grid-template-columns: auto auto;
    gap: 1rem;
    align-items: center;
    
} */
.invoice-box .btn-resident button{
    display: flex;
    align-items: center;
    gap: 3px;
 }
 .invoice-box .btn-resident button i{
    margin-right: 2px;
 }
 .invoice-box .new-message i{
    margin-right: 3px;
 }
 .invoice-box .resident .left-content .input-field.position-relative span img {
    position: absolute;
    width: 15px;
    top: 15px;
    right: 1rem;
}
.invoice-box .resident span.paid {
    background: #5BA55933;
    color: #2B5629;
    padding: 6px 0px;
    border-radius: 50px;
    width: 120px;
    display: block;
    /* margin: 0 auto; */
    text-align: center;
}
.invoice-box .resident span.unpaid {
    background: #E500001A;
    color: #EC0000;
    padding: 6px 0px;
    border-radius: 50px;
    width: 120px;
    display: block;
    /* margin: 0 auto; */
    text-align: center;
}
@media(max-width:450px){
    .invoice-box .popup-report-box .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .invoice-box .popup-report-box .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
    .invoice-box .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .invoice-box .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
}
