.rhdvata-main .breadcrumb .breadcrumb-item{
    font-size: .875rem;
    font-weight: 400;
    color: #646464;
}
.rhdvata-main .breadcrumb .breadcrumb-item a{
    font-size: .875rem;
    font-weight: 400;
    color: #646464;
}
.rhdvata-main .breadcrumb .breadcrumb-item a:hover{
    color: #00234F;
}
.rhdvata-main .rhdv-box{
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 12px;
    margin-bottom: 1.25rem;
    margin-top: 0.625rem;
}
.rhdvata-main .rhdv-box h3{
    color:#1C1C1C;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0px;
}
.rhdvata-main .rhdv-box img{
    width: 38px;
}
.rhdvata-main .attention-required-box{
    background-color: #D10D010D;
    width: 100%;
    padding: .5rem 1rem;
    border-radius: 6px;
}
.rhdvata-main .attention-required-box img{
    width: 24px;
}
.rhdvata-main .attention-required-box p{
    font-size: .875rem;
    font-weight: 600;
    color: #D10D01;
    margin-bottom: 0px;
}