
:root{
    --dashblue:#00234F;
    --btnblue:#003366;
    --themered:#D10D01;
    --textblack:#111111;
    --themegrey:#1C1C1C;
    --themelink:#1D4D86;
    --textgrey:#646464; 
}
.main-content.family-home{
    overflow: hidden;
}
.family-home .home {
    padding: 10px 10px;
    margin-top: 20px;
}

.family-home .welcome-image {
    width: 30%;
}

.family-home .welcome {
    background: #FFF3DC;
    position: relative;
    border-radius: 12px;
    padding-top: 13px;
}

.family-home .welcome .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.family-home .wellcome-txt h6 {
    color: #1C1C1C;
    font-size: 24px;
    font-weight: 600;
}

.family-home .wellcome-txt p {
    color: #1C1C1C;
    font-size: 16px;
    font-weight: 400;
}

.family-home .welcome-image img.star1 {
    width: 30px;
    position: absolute;
    top: 22px;
    right: 33px;
}

.family-home .welcome-image img.star2 {
    width: 30px;
    position: absolute;
    right: 234px;
    bottom: 6px;
}

.family-home .Patients {
    box-shadow: 0px 4px 40px 0px #0000000D;
    padding: 13px 12px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 0px;
    height: 100%;
}

.family-home .Patients .content .content-txt h5 {
    font-weight: 600;
}

.family-home .Patients .content-txt h5 span {
    font-size: 14px;
    color: #6F6F6F;
}

.family-home .Patients .icon img {
    width: 20%;
    margin-bottom: 11px;
}

.family-home .Patients .content .Blood-Pressure-graph {
    position: relative;
}

.family-home .Patients .content .Blood-Pressure-graph img {
    width: 100%;
    position: absolute;
    top: -34px;
}

.family-home .Patients .content .blood-pressure-warning {
    background-color: #d10d010d;
    display: flex;
    align-items: center;
    padding: 7px 8px;
    margin-top: 13px;
}

.family-home .Patients .content .blood-pressure-warning p {
    color: #d10d01;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
}

.family-home .Walking {
    display: flex;
    align-items: center;
    height: auto;
    justify-content: center;
    margin-bottom: 0px;
}

.family-home .Walking .icon img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 11px;
}

.family-home .Patients-section {
    margin-top: 15px;
}

.family-home .Patients h6 {
    font-size: 12px;
}

.family-home .Walking h6 {
    margin-bottom: 0px;
}

.family-home .medication-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: 100%;
}

.family-home .header {
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.family-home .header h2 {
    margin: 0;
    font-size: 20px;
}

.family-home .header p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
}

.family-home .timeline {
    padding-left: 10px;
    position: relative;
}

.family-home .timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 14px;
    height: 100%;
    width: 2px;
    background-color: #BFBFBF;
}

.family-home .timeline-item {
    position: relative;
    padding-left: 25px;
    margin-bottom: 51px;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.family-home .timeline-item:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0px;
    width: 10px;
    height: 10px;
    background-color: #1D4D86;
    border-radius: 50%;
}

.family-home .time {
    font-size: 14px;
    color: #555;
}

.family-home .medication {
    font-size: 16px;
    font-weight: bold;
}

.family-home .dose {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
}



.family-home .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.family-home .header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.family-home .header a {
    font-size: 14px;
    color: #003366;
    text-decoration: none;
    font-weight: 500;
}


.family-home .alert,
.report {
    border-radius: 8px;
    padding: 8px;
}

.family-home .report {
    background-color: transparent;
}

.family-home .report-item,
.alert-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.family-home .alert-item {
    background-color: #f7f7f7;
    display: block;
    padding: 7px 11px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.family-home .report-item:last-child,
.alert-item:last-child {
    border-bottom: none;
}

.family-home .report-info h3, .alert-item h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.family-home .report-info p {
    margin: 0;
    font-size: 12px;
    color: #555;
}

.family-home .report-icons .icon {
    margin-left: 6px;
    font-size: 16px;
}
.family-home .report-icons span.icon img {
    width: 20px;
}

.family-home .alert-item p {
    font-size: 14px;
    color: #777;
    margin-top: 5px;
}

/* .family-home .medical-report {
    margin-top: 20px;
} */

.family-home .medical-report-inner {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 10px;
    padding: 20px 16px;
    margin-bottom: 0px;
}
.family-home .medical-report-inner .alert {
    padding: 0px;
}
.family-home .alert {
    position: relative;
    padding: 0rem 0rem;
}
.family-home .blood-pressure-warning p{
    display: flex;
    justify-content: center;
    align-items: center;
}
.family-home .blood-pressure-warning img {
    width: 20px;
    margin-top: -1px;
}
.family-home .table-fix {
    display: grid;
    grid-template-columns: 25px 25px 25px;
    align-items: center;
    height: 56px;
}
/* Responsive css */

@media only screen and (max-width: 991px) {
    .family-home .home {
        margin-top: 66px;
    }
    .family-home .Walking {
        align-items: center;
    }
    .family-home .Walking {
        align-items: center !important;
        height: fit-content !important;
        /* display: block; */
        justify-content: flex-start;
    }
    .family-home .medication-container {
        margin-left: 0px;
    }

}

@media only screen and (max-width: 600px) {
    
    .family-home .Patients .content .Blood-Pressure-graph img {
        width: 20%;
        top: -71px;
        left: 79%;
    }
    .family-home .Walking .icon img {
        width: 60px;
        object-fit: cover;
        margin-bottom: 11px;
    }
    .family-home .welcome-image img.star2 {
        right: 268px;
        bottom: 36px;
    }
    .family-home .welcome-image img.star1 {
        top: 110px;
        right: 63px;
    }
    .family-home .welcome .container {
        display: block;
    }
    .family-home .welcome-image {
        width: 100%;
    }
    .col-md-3 {
        padding: 0px 14px;
    }
    .family-home .medication-container {
        width: 100% !important;
        margin-top: 12px;
    }
    .family-home .medical-report {
        width: 100% !important;
        margin-top: 15px;
    }
    .mtsm-t{
        margin-top: 1.5rem;
    }

}

/* chart box start */
.family-home .chart-box{
   background-color: #ffffff;
   box-shadow: 0px 4px 40px 0px #0000000F;
   border-radius: 10px;
   height: fit-content;
   padding: 0px;

}
.family-home .chart-box .chart-box-top h3{
    font-size: 18px;
    font-weight: 600;
}
.family-home .chart-box .chart-box-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 20px 10px;
}
.family-home .chart-box .chart-box-top .chart-options-box .input-field select{
    padding: 5px 7px;
    border: 1px solid #E6E7E9;
    border-radius: 5px;
}
.family-home .chart-box .css-10vg0a6-MuiResponsiveChart-container{
    width: 100% !important;
    height: 100% !important;
}
/* .family-home .css-yrgrvu{
    fill: #FAA600 !important;
 }
.family-home .css-1qsjhi9{
    fill: #FEDFA8 !important;
 }
 .family-home .chart-box .css-1qdzy9k-MuiBarElement-root {
    fill: #FAA600 !important;
}
.family-home .MuiChartsLegend-series:nth-child(1) rect.MuiChartsLegend-mark {
    fill: #FAA600 !important;
}
.family-home .MuiChartsLegend-series:nth-child(2) rect.MuiChartsLegend-mark{
    fill: #FEDFA8 !important;
}
.family-home .chart-box .css-3dcfwk-MuiBarElement-root {
    fill: #FEDFA8 !important;
} */
/* chart box end */
