
/* Responsive css */

@media only screen and (max-width: 991px) {
    .sign-up-section {
        padding: 0px 6px;
    }

    .sign-up-image {
        padding-top: 361px;
    }

    .sign-up-field .input-field input {
        width: 100%;
    }
    .welcome-image img.star1 {
        width: 20px;
    }
    .welcome-image img.star2 {
        width: 20px;
        right: 164px;
        bottom: 9px;
    }

}

@media only screen and (max-width: 600px) {
    .sign-up .container {
        width: 100%;
        padding: 0px;
    }

    .sign-up-field .input-field input {
        width: 100%;
        padding: 12px;
        border-radius: 10px;
        border: 1px solid #A3A3A3;
        margin-bottom: 15px;
        color: #A3A3A3;
    }

    .sign-up-wellcome img {
        width: 50%;
    }

    .sign-up-field .input-field button,
    .verify-otp .input-field button {
        width: 100%;

    }
    .sign-up-section {
        padding: 24px 6px !important;
    }
    .verify-otp-section {
        width: 91%;
    }
    .verify-otp .input-field input {
        width: 16%;
        margin: 11px 3px;
    }

}


/* .sign-up-image {
    background: url(../img/sign-up.jpeg);
    background-size: cover;
    padding-top: 410px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 2px -1px;
} */

.sign-up-overlay {
    background-color: #0000007d;
    color: #fff;
    padding: 32px 21px;
    font-family: 'Onest', sans-serif;
}

.sign-up-overlay p {
    font-size: 15px;
}

.sign-up-overlay h2 {
    font-size: 27px;
}

.sign-up-field .input-field label,
.verify-otp .input-field label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
}

.verify-otp .input-field label {
    margin-left: 10px;

}

.sign-up-wellcome img {
    width: 20%;
}

.sign-up-field .input-field input {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #A3A3A3;
    margin-bottom: 15px;
    color: #A3A3A3;
}

.sign-up-section {
    padding: 0px 50px;
}

.sign-up-field .input-field button,
.verify-otp .input-field button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 50px;
    background-color: #003366;
    color: #fff;
}

.verify-otp .input-field button {
    width: 80%;
}

.sign-up-section span {
    display: block;
    width: max-content;
    margin: auto;
    margin-top: 30px;
    font-weight: 500;

}

.sign-up-section span a {
    color: #1D4D86;
}

.sign-up {
    padding: 10px 17px;
}

.sign-up .row {
    align-items: center;
}

.sign-up-wellcome {
    margin-bottom: 20px;
}

.verify-otp .input-field input {
    width: 10%;
    margin: 10px 10px;
    padding: 10px;
    text-align: center;
    border: 1px solid #E3E3E3;
    border-radius: 10px;
}

.verify-otp-section {
    width: 80%;
    display: block;
    margin: auto;
}

.verify-otp span {
    width: 85%;
    display: block;
    margin: auto;
}

.verify-otp .input-field button {
    width: 75%;
    margin-top: 20px;
    margin-bottom: 42px;
}

.sign-up-field .nav-tabs {
    justify-content: center;
    border: none;
    background-color: #E5E9EF;
    width: max-content;
    margin: auto;
    padding: 4px 6px;
    border-radius: 50px;
}

.sign-up-field .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    padding: 3px 40px;
    border-radius: 50px;
    color: #003366;
    font-weight: 500;
}
.sign-up-field .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #003366;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

/* add code */
@media (min-width: 768px) and (max-width:1024px) {
    .container-md, .container-sm, .container {
        max-width: 800px !important;
    }
}
.left-img img {
    height: 572px;
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
}

.left-img-text{
    position: absolute;
    bottom: 0;
    padding: 1rem;
    color: #fff;
    background-color: #0000007d;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 0px 0px 10px 10px;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
.sign-up-wellcome h3 {
    font-size: 32px;
    font-weight: 600;
    color: #111111;
}
.sign-up-wellcome p{
    font-size: 18px;
    font-weight: 400;
    color:#646464
}
@media (max-width: 768px) {
    .left-img img {
        height: 300px !important;
        object-fit: cover;
        border-radius: 10px;
        width: 100%;
    }
    .left-img h2{
        font-size: 18px;
        font-weight: 500;
    }
    .left-img p{
        font-size: 14px;
        font-weight: 400;
    }
}