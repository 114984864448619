.alerts-main .alert-box h1{
    color: #1C1C1C;
    font-size: 1.375rem;
    font-weight: 600;
}
.alerts-main .alert-box .detected-box{
    background-color: #D10D011A;
    padding: 1rem;
    border-radius: 13px;
    margin-top: 1rem;
}
.alerts-main .alert-box .detected-box img{
    width: 40px;
}
.alerts-main .alert-box .detected-box h5{
    font-size: 1.125rem;
    color: #1C1C1C;
    font-weight: 600;
    margin-bottom: 2px;
}
.alerts-main .alert-box .detected-box h5 span{
    color: #920B02;
}
.alerts-main .alert-box .detected-box p{
    font-size: .75rem;
    color: #5B5B5B;
    font-weight: 500;
}
.alerts-main .alert-box .detected-box a{
    font-size: .875rem;
    color: #fff;
    font-weight: 500;
    background-color: #003366;
    padding: 1rem;
    border-radius: 100vmax;
}
.alerts-main .alerts-card{
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000D;
    border-radius: 13px;
    padding: 1rem 1rem .75rem;
    margin-top: 1.056rem;
}
.alerts-main .alerts-card h2.av{
    font-size: 1.125rem;
    color: #1C1C1C;
    font-weight: 600;
}
.alerts-main .alerts-card h2.av span img{
    padding-right: 6px;
    width: 10px;
}
.alerts-main .alerts-card .accordion-item{
    border: 1px solid #F3F3F3 !important;
    border-radius: 10px !important;
    margin-bottom: 15px;
    cursor: pointer;
}
.alerts-main .alerts-card .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}
.alerts-main .alerts-card .accordion-header {
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    padding: 5px 10px;
    align-items: center;
}
.alerts-main .alerts-card .accordion-header h4{
    font-size: 1rem;
    color: #1C1C1C;
    font-weight: 600;
    margin-bottom: 0px;
}
.alerts-main .alerts-card .accordion-header p{
    font-size: .75rem;
    color: #6F6F6F;
    font-weight: 400;
    margin-bottom: 0px;
}
.alerts-main .alerts-card .accordion-header h5{
    font-size: .875rem;
    font-weight: 500;
    color: #01885F;
    margin-bottom: 0px;
}
.alerts-main .alerts-card .accordion-header img{
    width: 36px;
    height: 36px;
}
.alerts-main .alerts-card button.accordion-button.collapsed{
    padding: 10px 10px;
}
.alerts-main .alerts-card .accordion-button::after {
    background-image: url('../../../../public/images/arrow-alerts.svg') !important;
    padding: 0rem;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
}
.alerts-main .alerts-card .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    padding: 5px 10px;
}
.alerts-main .alerts-card .alerts-data{
    border-top: 1.5px dashed #DADADA;
    padding-top: 10px;
}
.alerts-main .alerts-card .alerts-data h5{
    font-size: .875rem;
    color: #3C3C3C;
    font-weight: 500;
}
.alerts-main .alerts-card .alerts-data h6{
    font-size: .875rem;
    color: #3C3C3C;
    font-weight: 500;
}
.alerts-main .alerts-card .alerts-data h6 span{
    font-size: .875rem;
    color: #00C488;
    font-weight: 600;
}
.alerts-main .alerts-card.warning-br{
    border-left: 2px solid #FA9600;
}
.alerts-main .alerts-card.critical-br{
    border-left: 2px solid #D10D01;
}