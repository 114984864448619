.appointment-box {
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000D;
    border-radius: 12px;
    padding: 20px 16px;
}
.appointment-box .appointm-top{
    margin-bottom: 5px;
}
.appointment-box .appointm-top h3{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 4px;
}
.appointment-box .appointm-top p{
    font-size: 14px;
    color: #646464;
    font-weight: 400;
}
.appointment-box .appointm-top a{
    color: #003366;
    font-size: 14px;
    font-weight: 500;
}
.appointment-info {
    border-bottom: 1px solid #E6E7E9;
    padding: 12px 0px;
}
.appointment-info:last-child {
    border-bottom:none;
}

.appointment-info .left-appoint img{
    width: 48px;
    height: 48px;
}
.appointment-info .left-appoint h4{
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 4px;
}
p{
    font-size: 12px;
    font-weight: 400;
    color: #646464;
}