.rhdata-main .resident {
    box-shadow: 0px 4px 40px 0px #0000000D;
    margin-bottom: 0px;
    padding: 21px 16px;
    border-radius: 10px;
    border: .5px solid #f7f7f7;
}
.rhdata-main .resident thead.table tr th {
    font-weight: 500;
    font-size: 14px;
}
.rhdata-main .resident thead.table {
    background: #00234F;
    color: #fff;
}
.rhdata-main .resident .table-responsive {
    border-radius: 8px;
}
.rhdata-main .resident table th , .resident table td, .resident table tr{
    border: none;
}

.rhdata-main .resident .table th, .table td {
    vertical-align: middle;
    text-align: center;
}

.rhdata-main .resident .table th i{
    cursor: pointer;
    color: #7685A3;
}

.rhdata-main .resident .filtericon button {
    border: none;
    border-radius: 12px;
}
.rhdata-main .resident .left-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.rhdata-main .resident .text-success {
    color: #5BA559;
    font-weight: 600;
}
.rhdata-main button.btn.btn-sm {
    padding: 0px 2px;
}
.rhdata-main .resident .text-danger{
    color: #E50000;
    font-weight: 600;
}

.rhdata-main .resident .text-warning {
    color: #E17C00;
    font-weight: 600;
}

.rhdata-main .resident h5 {
    font-size: 16px;
    font-weight: 600;
}
.rhdata-main .checkbox i.bi.bi-arrow-down-up {
    margin-left: 5px;
    font-size: 10px;
}
.rhdata-main .resident thead.table tr th i {
    font-size: 11px;
}
.rhdata-main .resident .left-content .input-field input.form-control {
    padding: 11px 13px;
    border: none;
    background: #F3F3F4;
    color: #7685A3;
    border-radius: 10px;
}
.rhdata-main .resident .left-content .input-field input.form-control::placeholder{
    color: #7685A3;
}
.rhdata-main .resident button.btn.btn-primary {
    background-color: #003366;
    color: #fff;
    font-size: 14px;
    padding: 13px 19px;
    border-radius: 50px;
    border: 0;
}
.rhdata-main .resident thead.table {
    background: #00234F;
    color: #fff;
}
.rhdata-main .updown-arrow {
    width: 12px;
    height: 12px;
    fill: #7685A3;
    margin-inline: .3rem;
    padding: 0px !important;
    background-color: transparent !important;
    color: transparent;
}
.rhdata-main .table thead th {
    background-color: #00234F;
    color: white;
    text-align: center;
}
.rhdata-main .checkbox {
    padding: .5rem 0px !important;
    background-color: transparent !important;
    align-items: center;
}
.rhdata-main .checbox-trans input[type=checkbox] {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-right: 5px;
    margin-top: -1px;
}
.rhdata-main .table-stripedd>tbody>tr:nth-of-type(even)>td {
    background-color: #F6F6F6 !important;
}
.rhdata-main .table-stripedd>tbody>tr:nth-of-type(odd)>td {
    background-color: #FFFFFF !important;
}

.rhdata-main .resident .table-responsive{
    position: relative;
}
.rhdata-main .resident ul.edit-rt{
    border: 1px solid #fdfdfd;
}
.rhdata-main .borderbd{
    border-bottom: 1px dashed #f7f7f7;
}
.rhdata-main .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    border-color: transparent !important;
}
/* Responsive css */

@media only screen and (max-width: 991px) {
    .rhdata-main .home {
        margin-top: 66px;
    }

}
/* .d-grid.add-resident-box {
    grid-template-columns: auto auto;
    gap: 1rem;
    align-items: center;
    
} */
.rhdata-main .btn-resident button{
    display: flex;
    align-items: center;
    gap: 3px;
 }
 .rhdata-main .btn-resident button i{
    margin-right: 2px;
 }
 .rhdata-main .new-message i{
    margin-right: 3px;
 }
 .rhdata-main .resident .left-content .input-field.position-relative span img {
    position: absolute;
    width: 15px;
    top: 15px;
    right: 1rem;
}
.rhdata-main .resident span.paid {
    background: #5BA55933;
    color: #2B5629;
    padding: 6px 0px;
    border-radius: 50px;
    width: 120px;
    display: block;
    margin: 0 auto;
}
.rhdata-main .resident span.unpaid {
    background: #E500001A;
    color: #EC0000;
    padding: 6px 0px;
    border-radius: 50px;
    width: 120px;
    display: block;
    margin: 0 auto;
}
.rhdata-main .table-fix{
    display: grid;
    grid-template-columns: 25px 25px 25px;
    align-items: center;
    height: 56px;
}
.rhdata-main table{
    white-space: nowrap;
}
/* width */
.rhdata-main .table-responsive::-webkit-scrollbar {
    /* width: 15px; */
    height: 8px;
  }
  
  /* Track */
  .rhdata-main .table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .rhdata-main .table-responsive::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .rhdata-main .table-responsive::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.rhdata-main .staff-pagination ul.pagination {
    justify-content: center;
}
.rhdata-main .staff-pagination a.page-link {
    border: 0;
    background: transparent;
    color: #5B5B5B;
    font-size: 14px;
    font-weight: 400;
}
.rhdata-main .staff-pagination a.page-link.p-icon-next img {
    transform: rotate(180deg);
}
.rhdata-main .staff-pagination li.page-item.active {
    background: #E5E9EF;
    border-radius: 8px;
}
.rhdata-main .staff-pagination .page-link:focus {
    box-shadow: none !important;
}
.checbox-trans input[type=checkbox]:checked {
    background-color: #000;
}
.rhdata-main .welcome {
    background: #FFF3DC;
    position: relative;
    border-radius: 12px;
    grid-template-columns: 1fr auto;
    align-items: center;
}
.rhdata-main .welcome .wellcome-txt{
    padding: 1.5rem 0px 1.5rem 1.5rem;
}
.rhdata-main .welcome .wellcome-txt h6{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0px;
    color: #1C1C1C;
}
.rhdata-main .welcome .wellcome-txt p{
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 0px;
    color: #1C1C1C;
}
.rhdata-main .welcome .welcome-image{
     width: 100%;
     margin-top: 1rem;
}

@media(max-width:450px){
    .rhdata-main .popup-report-box .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .rhdata-main .popup-report-box .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
    .rhdata-main .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .rhdata-main .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
}
