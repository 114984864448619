.health-monitoring-box .page-header h2{
    font-size: 1.375rem;
    font-weight: 600;
    color: #1C1C1C;
    /* margin-bottom: 20px; */
 }
 .health-monitoring-box .input-field select {
    padding: 5px 7px;
    border: 1px solid #E6E7E9;
    border-radius: 5px;
}
.health-monitoring-box .heart-rate-top img{
    width: 54px;
    height: 54px    ;
}
.health-monitoring-box .heart-inner{
    margin-top: 16px;
}
.health-monitoring-box .heart-inner h3{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 2px;
}
.health-monitoring-box .heart-inner p{
    font-size: 14px;
    font-weight: 400;
    color: #6F6F6F;
}
.health-monitoring-box .heart-inner h5{
    font-size: 16px;
    font-weight: 500;
    color: #003366;
}
.health-monitoring-box .heart-inner h5 span{
    font-size: 14px;
    font-weight: 500;
    color: #6F6F6F;
}
.health-monitoring-box .sleep-circle .CircularProgressbar {
    width: 220px;
    vertical-align: middle;
    transform: rotate(4deg);
}
.health-monitoring-box .sleep-circle .CircularProgressbar .CircularProgressbar-path {
    stroke: #5BA559;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}
.health-monitoring-box .sleep-circle img{
    width: 210px;
    height: 210px;
}
/* chart box start */
.health-monitoring-box  .chart-box{
    height: fit-content;
    padding: 0px;
 
 }
.health-monitoring-box  .chart-box .chart-box-top h3{
     font-size: 18px;
     font-weight: 600;
 }
.health-monitoring-box  .chart-box .chart-box-top{
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     padding: 20px 20px 10px;
 }
.health-monitoring-box  .chart-box .chart-box-top .chart-options-box .input-field select{
     padding: 5px 7px;
     border: 1px solid #E6E7E9;
     border-radius: 5px;
 }
.health-monitoring-box  .chart-box .css-10vg0a6-MuiResponsiveChart-container{
     width: 100% !important;
     height: 100% !important;
 }
 .chart-box .MuiBar-root {
    width: 10px !important; /* Set the desired bar width */
  }
 /*.health-monitoring-box  .css-yrgrvu{
     fill: #FAA600 !important;
  }
.health-monitoring-box  .css-1qsjhi9{
     fill: #FEDFA8 !important;
  }
 .health-monitoring-box  .chart-box .css-1qdzy9k-MuiBarElement-root {
     fill: #FAA600 !important;
 }
.health-monitoring-box  .MuiChartsLegend-series:nth-child(1) rect.MuiChartsLegend-mark {
     fill: #FAA600 !important;
 }
.health-monitoring-box  .MuiChartsLegend-series:nth-child(2) rect.MuiChartsLegend-mark{
     fill: #FEDFA8 !important;
 }
.health-monitoring-box  .chart-box .css-3dcfwk-MuiBarElement-root {
     fill: #FEDFA8 !important;
 } */
 /* chart box end */