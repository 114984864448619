.patient-health-box .home {
    padding: 10px 10px;
    margin-top: 10px;
}
.patient-health-box .breadcrumb-item a{
    color: #646464;
    font-size: 14px;
    font-weight: 600;
}
.patient-health-box .breadcrumb-item.active{
    color: #1c1c1c !important;
    font-weight: 600;
    font-size: 14px;
}

/* chart box start */
.patient-health-box  .chart-box{
    background-color: #ffffff;
    box-shadow: 0px 4px 40px 0px #0000000F;
    border-radius: 10px;
    height: fit-content;
    padding: 0px;
 
 }
.patient-health-box .chart-box .chart-box-top h3{
     font-size: 18px;
     font-weight: 600;
 }
.patient-health-box .chart-box .chart-box-top{
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     padding: 20px 16px 10px;
 }
.patient-health-box .chart-box .chart-box-top .chart-options-box .input-field select{
     padding: 5px 7px;
     border: 1px solid #E6E7E9;
     border-radius: 5px;
 }
 .patient-health-box .left-content .input-field select{
    padding: 5px 7px;
    border: 1px solid #E6E7E9;
    border-radius: 5px;
}

.patient-health-box .chart-box .css-10vg0a6-MuiResponsiveChart-container{
     width: 100% !important;
     height: 100% !important;
 }
 .css-rccht4{
    width: 100% !important;
     height: 100% !important;
 }
 /* .patient-health-box .MuiChartsLegend-series:nth-child(1) rect.MuiChartsLegend-mark {
    fill: #FAA600 !important;
}
.patient-health-box .MuiChartsLegend-series:nth-child(2) rect.MuiChartsLegend-mark{
    fill: #FEDFA8 !important;
}
.patient-health-box.css-yrgrvu{
    fill: #FAA600 !important;
 }
 .patient-health-box .css-1qsjhi9{
    fill: #FEDFA8 !important;
 }
 .patient-health-box .chart-box .css-1qdzy9k-MuiBarElement-root {
    fill: #FAA600 !important;
}
.patient-health-box .chart-box .css-3dcfwk-MuiBarElement-root {
    fill: #FEDFA8 !important;
} */
 /* chart box end */

 /* Resident Table Code Start */
 .patient-health-box .resident {
    box-shadow: 0px 4px 40px 0px #0000000D;
    margin-bottom: 0px;
    padding: 21px 16px;
    border-radius: 10px;
    border: .5px solid #f7f7f7;
}
.patient-health-box .resident thead.table tr th {
    font-weight: 500;
    font-size: 14px;
}
.patient-health-box .resident thead.table {
    background: #00234F;
    color: #fff;
}
.patient-health-box .resident .table-responsive {
    border-radius: 8px;
}
.patient-health-box .resident table th , .resident table td, .resident table tr{
    border: none;
}

.patient-health-box .resident .table th, .table td {
    vertical-align: middle;
    text-align: center;
}

.patient-health-box .resident .table th i{
    cursor: pointer;
    color: #7685A3;
}

.patient-health-box .resident .filtericon button {
    border: none;
    border-radius: 12px;
}
.patient-health-box .resident .left-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.patient-health-box .resident .text-success {
    color: #5BA559;
    font-weight: 600;
}
.patient-health-box button.btn.btn-sm {
    padding: 0px 2px;
}
.patient-health-box .resident .text-danger{
    color: #E50000;
    font-weight: 600;
}

.patient-health-box .resident .text-warning {
    color: #E17C00;
    font-weight: 600;
}

.patient-health-box .resident h5 {
    font-size: 16px;
    font-weight: 600;
}
.patient-health-box .checkbox i.bi.bi-arrow-down-up {
    margin-left: 5px;
    font-size: 10px;
}
.patient-health-box .resident thead.table tr th i {
    font-size: 11px;
}
.patient-health-box .resident .left-content .input-field input.form-control {
    padding: 11px 13px;
    border: none;
    background: #F3F3F4;
    color: #7685A3;
    border-radius: 10px;
}
.patient-health-box .resident .left-content .input-field input.form-control::placeholder{
    color: #7685A3;
}
.patient-health-box .resident button.btn.btn-primary {
    background-color: #003366;
    color: #fff;
    font-size: 14px;
    padding: 13px 19px;
    border-radius: 50px;
    border: 0;
}
.patient-health-box .resident thead.table {
    background: #00234F;
    color: #fff;
}
.patient-health-box .updown-arrow {
    width: 12px;
    height: 12px;
    fill: #7685A3;
    margin-inline: .3rem;
    padding: 0px !important;
    background-color: transparent !important;
    color: transparent;
}
.patient-health-box .table thead th {
    background-color: #00234F;
    color: white;
    text-align: center;
}
.patient-health-box .checkbox {
    padding: .5rem 0px !important;
    background-color: transparent !important;
    align-items: center;
}
.patient-health-box .checbox-trans input[type=checkbox] {
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-right: 5px;
    margin-top: -1px;
}
.patient-health-box .table-stripedd>tbody>tr:nth-of-type(even)>td {
    background-color: #F6F6F6 !important;
}
.patient-health-box .table-stripedd>tbody>tr:nth-of-type(odd)>td {
    background-color: #FFFFFF !important;
}

.patient-health-box .resident .table-responsive{
    position: relative;
}
.patient-health-box .resident ul.edit-rt{
    border: 1px solid #fdfdfd;
}
.patient-health-box .borderbd{
    border-bottom: 1px dashed #f7f7f7;
}
.patient-health-box .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    border-color: transparent !important;
}
/* Responsive css */
/* .d-grid.add-resident-box {
    grid-template-columns: auto auto;
    gap: 1rem;
    align-items: center;
    
} */
.patient-health-box .btn-resident button{
    display: flex;
    align-items: center;
    gap: 3px;
 }
 .patient-health-box .btn-resident button i{
    margin-right: 2px;
 }
 .patient-health-box .new-message i{
    margin-right: 3px;
 }
 .patient-health-box .resident .left-content .input-field.position-relative span img {
    position: absolute;
    width: 15px;
    top: 15px;
    right: 1rem;
}
.patient-health-box .resident span.paid {
    background: #5BA55933;
    color: #2B5629;
    padding: 6px 0px;
    border-radius: 50px;
    width: 120px;
    display: block;
    margin: 0 auto;
}
.patient-health-box .resident span.unpaid {
    background: #E500001A;
    color: #EC0000;
    padding: 6px 0px;
    border-radius: 50px;
    width: 120px;
    display: block;
    margin: 0 auto;
}
.patient-health-box .table-fix{
    display: grid;
    grid-template-columns: 25px 25px 25px;
    align-items: center;
    height: 56px;
}
.patient-health-box table{
    white-space: nowrap;
}
/* width */
.patient-health-box .table-responsive::-webkit-scrollbar {
    /* width: 15px; */
    height: 8px;
  }
  
  /* Track */
  .patient-health-box .table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .patient-health-box .table-responsive::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .patient-health-box .table-responsive::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.patient-health-box .staff-pagination ul.pagination {
    justify-content: center;
}
.patient-health-box .staff-pagination a.page-link {
    border: 0;
    background: transparent;
    color: #5B5B5B;
    font-size: 14px;
    font-weight: 400;
}
.patient-health-box .staff-pagination a.page-link.p-icon-next img {
    transform: rotate(180deg);
}
.patient-health-box .staff-pagination li.page-item.active {
    background: #E5E9EF;
    border-radius: 8px;
}
.patient-health-box .staff-pagination .page-link:focus {
    box-shadow: none !important;
}
.checbox-trans input[type=checkbox]:checked {
    background-color: #000;
}

@media(max-width:450px){
    .patient-health-box .popup-report-box .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .patient-health-box .popup-report-box .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
    .patient-health-box .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .patient-health-box .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
}


 /* Resident Table Code End */

/* medication code start */
 .patient-health-box .medication-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    /* height: 100%; */
    margin-top: 20px;
}
 .patient-health-box  .header {
    padding-bottom: 10px;
    margin-bottom: 20px;
}

 .patient-health-box  .header h2 {
    margin: 0;
    font-size: 20px;
}

 .patient-health-box  .header p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
}

 .patient-health-box  .timeline {
    padding-left: 10px;
    position: relative;
}

 .patient-health-box  .timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 14px;
    height: 100%;
    width: 2px;
    background-color: #BFBFBF;
}

 .patient-health-box  .timeline-item {
    position: relative;
    padding-left: 25px;
    margin-bottom: 51px;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

 .patient-health-box  .timeline-item:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0px;
    width: 10px;
    height: 10px;
    background-color: #1D4D86;
    border-radius: 50%;
}

 .patient-health-box  .time {
    font-size: 14px;
    color: #555;
}

 .patient-health-box  .medication {
    font-size: 16px;
    font-weight: bold;
}

 .patient-health-box  .dose {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
}



 .patient-health-box  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

 .patient-health-box  .header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

 .patient-health-box  .header a {
    font-size: 14px;
    color: #003366;
    text-decoration: none;
    font-weight: 500;
}
/* medication code End */

/* Incidents Code Start */
.patient-health-box  .medical-report-inner {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 10px;
    padding: 20px 16px;
    margin-bottom: 0px;
    margin-top: 20px;
}
 .patient-health-box  .medical-report-inner .alert {
    padding: 0px;
}
 .patient-health-box  .alert {
    position: relative;
    padding: 0rem 0rem;
}
 .patient-health-box  .blood-pressure-warning p{
    display: flex;
    justify-content: center;
    align-items: center;
}
 .patient-health-box  .blood-pressure-warning img {
    width: 20px;
    margin-top: -1px;
}
 .patient-health-box  .table-fix {
    display: grid;
    grid-template-columns: 25px 25px 25px;
    align-items: center;
    height: 56px;
}
 .patient-health-box  .alert-item {
    background-color: #f7f7f7;
    display: block;
    padding: 7px 11px;
    border-radius: 10px;
    margin-bottom: 10px;
}
 .patient-health-box .alert-item p {
    font-size: 14px;
    color: #777;
    margin-top: 5px;
}
/* Incidents Code Start */

/* Caretakers Code Start */
.patient-health-box .caretakers-box{
    box-shadow: 0px 4px 40px 0px #0000000D;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px 16px;
    border-radius: 10px;
}
.patient-health-box .caretakers-box{
    margin-bottom: 16px;
}
.patient-health-box .caretakers-box h2{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 0px;
}
.patient-health-box .caretakers-box p.txt-grey{
    font-size: 14px;
    font-weight: 400;
    color: #6F6F6F;
}
.patient-health-box .caretakers-box .ct-left img{
  width: 48px;
  height: 48px;
}
.patient-health-box .caretakers-box .ct-left .ct-name h4{
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 5px;
}
.patient-health-box .caretakers-box .ct-left .ct-name p{
    color: #6F6F6F;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
}
.patient-health-box .caretakers-box .ct-right img{
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.patient-health-box .caretakers-box .ct-right p{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
}
/* Caretakers Code End */


@media (max-width:576px){
    .patient-health-box li.breadcrumb-item {
        font-size: 11px;
        margin-top: .5rem;
    }    
}
@media only screen and (max-width: 991px) {
    .patient-health-box .home {
        margin-top: 66px;
    }
}