a {
    text-decoration: none !important;
    color: #003366;
}
a.new-message{
    color: #003366;
}
p{
    margin-bottom: 0px !important;
}
.doctor-box .checbox-trans input[type=checkbox]{
    width: 20px;
    height: 20px;
    background-color: transparent;
}

.doctor-box .checbox-trans input[type=checkbox]:checked{
    background-color: #000;
}
.doctor-box .checkbox input {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    margin-top: -1px;
}
.doctor-box .checkbox {
    display: flex;
    align-items: center;
}
.doctor-box .table-stripedd>tbody>tr:nth-of-type(even)>td {
    background-color: #F6F6F6 !important;
}
.doctor-box .table-stripedd>tbody>tr:nth-of-type(odd)>td {
    background-color: #FFFFFF !important;
}

.doctor-box .home {
    padding: 10px 10px;
    margin-top: 20px;
}

.doctor-box .welcome-image {
    width: 30%;

}
.doctor-box .welcome-image.lady-doctor{
    margin-right: 20px;
}

.doctor-box .welcome {
    background: #FFF3DC;
    position: relative;
    border-radius: 12px;
    padding-top: 13px;
}

.doctor-box .welcome .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.doctor-box .wellcome-txt h6 {
    color: #1C1C1C;
}

.doctor-box .wellcome-txt p {
    color: #1C1C1C;
    font-size: 12px;
}

.doctor-box .welcome-image img.star1 {
    width: 30px;
    position: absolute;
    top: 22px;
    right: 33px;
}

.doctor-box .welcome-image img.star2 {
    width: 30px;
    position: absolute;
    right: 234px;
    bottom: 6px;
}
.doctor-box .lady-doctor img.star2 {
    width: 30px;
    position: absolute;
    right: 180px;
    bottom: 9px;
}
.doctor-box .resident {
    box-shadow: 0px 4px 40px 0px #0000000D;
    margin-bottom: 26px;
    padding: 21px 16px;
    border-radius: 10px;
}
.doctor-box .resident thead.table tr th {
    font-weight: 500;
    font-size: 14px;
}
.doctor-box .resident thead.table {
    background: #00234F;
    color: #fff;
}
.doctor-box .resident .table-responsive {
    border-radius: 8px;
}
.doctor-box .resident table th , .resident table td, .resident table tr{
    border: none;
}

.doctor-box .resident .table th, .table td {
    vertical-align: middle;
    text-align: center;
}

.doctor-box .resident .table th i{
    cursor: pointer;
    color: #7685A3;
}

.doctor-box .resident .filtericon button {
    border: none;
    border-radius: 12px;
}
.doctor-box .resident .left-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.doctor-box .resident .text-success {
    color: #5BA559;
    font-weight: 600;
}

.doctor-box .resident .text-warning {
    color: #E17C00;
    font-weight: 600;
}

.doctor-box .resident h5 {
    font-size: 16px;
    font-weight: 600;
}
.doctor-box .checkbox i.bi.bi-arrow-down-up {
    margin-left: 5px;
    font-size: 10px;
}
.doctor-box .resident thead.table tr th i {
    font-size: 11px;
}
.doctor-box .resident .left-content .input-field input.form-control {
    padding: 11px 13px;
    border: none;
    background: #F3F3F4;
    color: #7685A3;
    border-radius: 10px;
}
.doctor-box .resident .left-content .input-field input.form-control::placeholder{
    color: #7685A3;
}
.doctor-box .resident button.btn.btn-primary {
    background-color: #003366;
    color: #fff;
    font-size: 14px;
    padding: 13px 19px;
    border-radius: 50px;
    border: 0;
}
.doctor-box .wellcome-txt h6 {
    font-size: 20px;
    font-weight: 600;
    color: #1C1C1C;
}
.doctor-box .wellcome-txt p {
    font-size: 16px;
    font-weight: 400;
    color: #1C1C1C;
}

.doctor-box .alerts-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 40px 0px #0000000D;;
    padding: 16px 20px;
    /* max-width: 400px; */
    margin: auto;
    margin-top: 20px;
}
.doctor-box .alerts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}

.doctor-box .alerts-header h3 {
    font-size: 18px;
    font-weight: 600;
}

.doctor-box .alerts-header .view-all {
    color: #003366;
    font-weight: 500;
    font-size: 14px;
}

.doctor-box .alerts-header .view-all:hover {
    text-decoration: underline;
}

.doctor-box .alert-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    justify-content: flex-start;
}
.doctor-box .alert-item:last-child {
    border-bottom: none;
}

.doctor-box .profile-pic {
    width: 48px;
    height: 48px;
    margin-right: 8px;
}

.doctor-box .alert-info h4 {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
}

.doctor-box .alert-info p {
    font-size: 12px;
    color: #777;
    margin-bottom: 0px;
}
.doctor-box .alert-info {
    background-color: transparent;
}
.doctor-box .attention-required {
    display: flex;
    align-items: center;
    background-color: #D10D010D;
    color: #D10D01;
    padding: 10px;
    border-radius: 8px;
    margin-top: 6px;
}

.doctor-box .attention-icon {
    font-size: 18px !important;
    margin-right: 10px;
}
.doctor-box .attention-required span {
    font-size: 14px;
    font-weight: 600;
}
.doctor-box .table thead th {
    background-color: #00234F;
    color: white;
    text-align: center;
}
.doctor-box .medical-reports a{
    text-decoration: none;
    color: #003366;
    font-weight: 500;
}

.doctor-box .table thead th span {
    font-size: 10px;
    color: #7685A3;
    cursor: pointer;
}

.doctor-box .btn-light {
    font-size: 18px;
    background-color: transparent;
    border: none;
}
.doctor-box .medical-reports h5 {
    font-size: 18px;
    font-weight: 600;
    color: #1c1c1c;
    margin: 0px;
}
.doctor-box .medical-reports {
    box-shadow: 0px 4px 40px 0px #0000000D;
    padding: 15px 10px;
    border-radius: 10px;
    margin-top: 20px;
}
.doctor-box .medical-reports .table-responsive {
    border-radius: 10px;
    border: 1px solid #F3F3F3;
}
.doctor-box .medical-reports tr td{
    border: none;
}
.doctor-box .medical-reports table{
    margin-bottom: 0px;
}

.doctor-box .messages-container {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 40px 0px #0000000D;
    margin-top: 20px;
}

.doctor-box .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.doctor-box .header h2 {
    margin: 0;
    font-size: 18px;
}
.doctor-box .header-tow h2{
    font-size: 16px !important;
}
.doctor-box .header-tow {
    align-items: baseline;
}
.doctor-box .header-tow h2{
    font-size: 14px;
}
.doctor-box .header-tow a{
    font-size: 14px;
}
.doctor-box .time button {
    border: none;
    background: transparent;
    margin-left: 10px;
    font-size: 15px;
}
.doctor-box.new-message {
    color: #003366;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}

.doctor-box .search-bar {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 15px;
}

.doctor-box .search-bar input {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
}

.doctor-box .message-list {
    margin-bottom: 20px;
}

.doctor-box .message-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e9ecef;
}

/* .doctor-box .message-item:last-child {
    border-bottom: none;
} */

.doctor-box .message-item img {
    margin-right: 15px;
    width: 48px;
    height: 48px;
    border-radius: 15px;
}

.doctor-box .message-content {
    flex-grow: 1;
}

.doctor-box .message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.message-header .name {
    font-weight: 600;
    font-size: 14px;
}

.message-header .time {
    font-size: 12px;
    color: #6c757d;
}
.doctor-box .header p{
    font-size: 12px;
    color: #6c757d;;
}

.doctor-box .message-content p {
    margin: 0;
    font-size: 14px;
    color: #6c757d;
}

.doctor-box .messages-container .view-all { 
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    color: #003366;
    width: max-content;
    margin: auto;
    display: block;
    padding: 10px 70px;
    border: 2px solid #003366;
    background-color: transparent;
}
.doctor-box ul.dropdown-menu.edit-button {
    border: none;
    background-color: #ffff;
    box-shadow: 0px 0px 3px black;
}
.doctor-box .edit-button a.dropdown-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.doctor-box .resident .table-responsive{
    position: relative;
}
.doctor-box .resident ul.edit-rt{
    border: 1px solid #fdfdfd;
}
.doctor-box .borderbd{
    border-bottom: 1px dashed #f7f7f7;
}
.doctor-box .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    border-color: transparent !important;
}
/* Responsive css */

@media only screen and (max-width: 991px) {
    .doctor-box .home {
        margin-top: 66px;
    }
    .doctor-box .Walking {
        align-items: center;
    }
    .doctor-box .Walking {
        align-items: center !important;
        height: 94px !important;
    }
    .doctor-box .medication-container {
        margin-left: 13px;
    }

}

@media only screen and (max-width: 600px) {
    
    .doctor-box .Patients .content .Blood-Pressure-graph img {
        width: 20%;
        top: -71px;
        left: 79%;
    }
    .doctor-box .welcome-image img.star2 {
        right: 268px;
        bottom: 36px;
    }
    .doctor-box .welcome-image img.star1 {
        top: 110px;
        right: 63px;
    }
    .doctor-box .welcome .container {
        display: block;
    }
    .doctor-box .welcome-image {
        width: 100%;
    }
    .doctor-box .medication-container {
        width: 92% !important;
        margin-top: 12px;
    }
    .doctor-box .medical-report {
        width: 100% !important;
        margin-top: 15px;
    }
    .doctor-box .messages-container {
        margin-top: 20px;
        /* margin-bottom: 20px; */
    }

}
.doctor-box .checkbox {
    padding: .5rem 0px !important;
    background-color: transparent !important;
}
.doctor-box .updown-arrow{
    width: 12px;
    height: 12px;
    fill: #7685A3;
    margin-inline: .3rem;
    padding: 0px !important;
    background-color: transparent !important;
    color: transparent;
}
/* .d-grid.add-resident-box {
    grid-template-columns: auto auto;
    gap: 1rem;
    align-items: center;
    
} */
.doctor-box .btn-resident button{
    display: flex;
    align-items: center;
    gap: 3px;
 }
 .doctor-box .btn-resident button i{
    margin-right: 2px;
 }
 .doctor-box .new-message i{
    margin-right: 3px;
 }
 .doctor-box .resident .left-content .input-field.position-relative span img {
    position: absolute;
    width: 15px;
    top: 15px;
    right: 1rem;
}
.doctor-box .medical-reports .table thead th {
    background-color: #00234F;
    color: white;
    text-align: start;
    font-size: 13px;
}
.doctor-box .table-fix {
    display: grid;
    grid-template-columns: 25px 25px 25px;
    align-items: center;
    height: 56px;
}
.doctor-box button.btn.btn-sm.btn {
    padding: 0 2px;
}

/* To Do List start */
.doctor-box .to-do-list-box{
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 40px 0px #0000000D;
    margin-top: 20px;
}
.doctor-box .to-do-list-box .to-do-header h3{
    font-size: 18px;
    font-weight: 600;
    color: #1C1C1C;
    margin-bottom: 0px;
}
.doctor-box .to-do-list-box .to-do-header p{
    font-size: 14px;
    font-weight: 400;
    color: #646464;
    margin-bottom: 0px;
}
.doctor-box .to-do-list-box .to-do-meeting-card{
    margin-top: 4px;
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;

}
.doctor-box .to-do-list-box .to-do-meeting-card:last-child{
    border-bottom: none;
}
.doctor-box .to-do-list-box .to-do-meeting-card label.form-check-label {
    margin-top: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
}
.form-check-input:focus {
    border-color: #FA9E57 !important;
    outline: 0;
    box-shadow: none !important;
}
.form-check-input:checked {
    border-color: #FA9E57 !important;
}
/* To DO List End */


@media(max-width:450px){
    .doctor-box .popup-report-box .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .doctor-box .popup-report-box .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
    .doctor-box .resident .left-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr auto;
    }
    .doctor-box .resident .btn-resident button{
           width: 100%;
           justify-content: center;
    }
}

