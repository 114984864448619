*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}


input[type='radio'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: 2px solid #FFEDD5;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
    outline: none;
}

input[type="radio"]:checked:before {
    background: #FA9E57;
    
}

input[type="radio"]:checked {
 border-color: orange;
}
a{
    text-decoration: none;
}
input[type=checkbox]{
    width: 20px;
    height: 20px;
    background-color: #FFF3DC;
}

input[type=checkbox]:checked{
    background-color: #FFF3DC;
}

.home {
    /* padding: 10px 10px; */
    margin-top: 30px;
    margin-bottom: 15px;
}

.form-tab .nav-tabs {
    border: none;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    padding-bottom: .5rem;
}
.form-tab .nav-tabs li {
    width: 100%;
}
/* width */
.form-tab .nav-tabs::-webkit-scrollbar {
    height: 6px;
  }
  
  .form-tab .nav-tabs::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  .form-tab .nav-tabs::-webkit-scrollbar-thumb {
    background: #eee; 
  }
  
  .form-tab .nav-tabs::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }

.new-reg-form {
    box-shadow: 0px 4px 40px 0px #0000000D;
    padding: 15px 16px;
    background-color: #fff;
    border-radius: 10px;
}

.new-reg-form h6 {
    font-size: 18px;
    font-weight: 600;
    color: #111111;
}

.new-reg-form p {
    font-size: 14px;
    color: #646464;
}

.forms {
    padding: 0px 20px 10px;
}

.form-tab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #003366 !important;
    background-color: #fff;
    border: none;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 2px solid #003366 !important;
}

.form-tab .nav-tabs .nav-link:focus, .nav-tabs .nav-link {
    font-size: 14px;
    color: #5B5B5B;
}

.form-tab .nav-link {
    display: block;
    padding: 0;
    margin-right: 20px;
    padding: 10px 0px;
}

.form-tab .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border: none;
}

.form .tab-content .tab-pane {
    margin-top: 20px;
    box-shadow: 0px 4px 40px 0px #0000000D;
    padding: 40px 20px 10px;
}

.form .tab-content .tab-pane h5 {
    border-left: 3px solid #F78A00;
    padding: 0px 12px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}

.forms .input-field label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
}

.forms .input-field input {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #E3E3E3;
    margin-bottom: 20px;
    color: #A3A3A3;
}

.residential .input-field-two label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
}

.residential .input-field-two input {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #E3E3E3;
    margin-bottom: 20px;
    color: #A3A3A3;
    padding-bottom: 30px;
}

.forms .input-field select {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #E3E3E3;
    margin-bottom: 20px;
    color: #A3A3A3;
}

.child p {
    font-size: 14px;
    font-weight: 500;
}

.residential {
    margin-top: 20px;
}

.checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
    align-items: end;
}

/* .checkbox input{
    width: 30px;
    height: 17px;
    background-color: #333;
}
.checkbox input[type=checkbox]:checked{
    background-color: #000;
} */

.checkbox label {
    font-size: 14px;
    font-weight: 500;
}

input[type=checkbox]:checked {
    background-color: #FA9E57;
}

.contact-inforamtion {
    margin-top: 40px;
}

.form {
    box-shadow: 0px 4px 40px 0px #0000000D;
    border-radius: 15px;
}

.forms button {
    width: max-content;
    padding: 10px 60px;
    border: none;
    border-radius: 50px;
    background-color: #003366;
    color: #fff;
    margin-top: 20px;
}

.forms ul li {
    list-style: unset;
    font-weight: 500;
}

.forms ul {
    margin-bottom: 12px;
    padding: 0px;
}


.Specialist table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.Specialist table thead {
    background-color: #f2f2f2;
}

.Specialist table thead th {

    color: #333;
    text-align: left;
    padding: 10px;
    font-weight: 500;
    background-color: transparent !important;
}

.Specialist table tbody td {
    padding: 10px;
    width: 10%;
    text-align: left;
}

.Specialist .input-field input {
    margin-bottom: 0px;
}

.Specialist table tbody td:first-child {
    width: .2%;
}
.Specialist table tbody td:last-child {
    width: .8%;
}

.headig .add-button button {
    margin-top: 0;
    padding: 0;
    background-color: transparent !important;
    color: #003366;
    font-weight: 500;
    padding: 0px 30px;
}

.headig {
    display: flex;
    justify-content: space-between;
}

.Specialist {
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #E3E3E3;
    margin-bottom: 30px;
}

.headig-two ul {
    margin-bottom: 5px;
}

.vaccination-history {
    background-color: #ffffff;
}


.vaccination-item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.vaccination {
    margin-bottom: 20px;
    width: 22%;
}

.vaccination span {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
}

.options {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.last-dose {
    font-size: 14px;
}

.last-dose input {
    border: none;
    border-bottom: 1px solid #333;
    outline: none;
    margin-left: 10px;
    width: 55%;
}

.assistive-devices {
    background-color: #ffffff;
}

.assistive-devices ul {
    list-style-type: none;
    padding: 0;
    margin: 0px;
}

.assistive-devices li,
.assistive-devices ol {
    margin-bottom: 10px;
    padding: 0px;
}

.Advanced ul {
    padding: 0px;
    margin: 0px;
}

.assistive-devices label {
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
}

.assistive-devices input[type="checkbox"] {
    margin-right: 10px;
}

.form-group {
    margin-bottom: 13px;
}

.form-check {
    margin-bottom: 6px !important;
}
.forms p{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
}
.forms span {
    font-weight: 600;
    font-size: 15px;
}
.main-content{
    overflow-x: hidden;
}
.consent-box ul{
    list-style: disc;
    margin-bottom: 0px;
    padding-left: 22px !important;
}
.officeuse-box ul li{
    font-weight: 600 !important;
}
.officeuse-box ul{
    margin-left: 30px;
}
.new-reg-form button.nav-link.active{
    color: #003366 !important;
}
.new-reg-form button.nav-link:hover{
    color: #003366 !important;
}

@media only screen and (max-width: 991px){
    .home {
        padding: 10px 10px;
        margin-top: 65px;
    }
}
@media only screen and (max-width: 700px) {
    .home {
        padding: 10px 10px;
        margin-top: 65px;
    }
    .forms button {
        width: 100%;
        padding: 10px 60px;
        border: none;
        border-radius: 50px;
        background-color: #003366;
        color: #fff;
        margin-top: 40px;
    }
    .options {
        display: grid;
        gap: 15px;
        margin-bottom: 15px;
        margin-left: 35px;
    }
    .last-dose input {
        border: none;
        border-bottom: 1px solid #333;
        outline: none;
        margin-left: 10px;
        width: 100%;
    }
}
@media (min-width: 1101px) {
    .main-content {
        min-height: calc(100vh - 8rem);
        margin-block-end: 0;
        margin-block-start: 3rem;
        margin-inline-start: 16%;
        margin-inline-end: 1.5%;
        transition: all 0.05s ease;
    }
}
@media only screen and (min-width: 992px) and (max-width:1100px){
    .main-content {
        min-height: calc(100vh - 8rem);
        margin-block-end: 0;
        margin-block-start: 3.25rem;
        margin-inline-start: 21%;
        transition: all 0.05s ease;
    }
}
@media only screen and (min-width: 768px) and (max-width:1100px){
    .vaccination {
        margin-bottom: 20px;
        width: 50%;
    }
}
@media only screen and (min-width: 320px) and (max-width:767px){
    .vaccination {
        margin-bottom: 20px;
        width: 100% !important;
    }
}
.nav-tabs .nav-link {
    border: none !important;
}

.action {
    padding: 0px 3px !important;
    width: auto !important;
    background-color: transparent !important;
    margin: auto !important;
}

tr, td,th{
    border: none !important;
}
.emergancy_check{
  display: inline-block;
  width: 4%!important;
  margin-right: 12px;

}