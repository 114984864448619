@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap');

:root{
    --dashblue:#00234F;
    --btnblue:#003366;
    --themered:#D10D01;
    --textblack:#111111;
    --themegrey:#1C1C1C;
    --themelink:#1D4D86;
    --textgrey:#646464; 
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Onest", sans-serif;
    scrollbar-width: thin; /* 'auto' or 'thin' */
    scrollbar-color: #0b141a33 #f1f1f1; /* thumb color and track color */
}

ul {
    padding: 0px;
    margin: 0px;
}

img {
    width: 100%;
}

ul li {
    list-style: none;
    font-family:'Onest', sans-serif;
}

a {
    text-decoration: none;
    font-family:'Onest', sans-serif;
}
.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--btnblue) !important;
}

body {
  margin: 0;
  font-family:'Onest', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Onest', sans-serif;
}
.breadcrumb-item a{
    color: #646464;
    font-size: 14px;
    font-weight: 600;
}
.breadcrumb-item.active a{
    color: #1c1c1c !important;
    font-weight: 600;
    font-size: 14px;
    margin-top: 2px;
}
@media (max-width:576px){
    li.breadcrumb-item {
        font-size: 11px;
        margin-top: .5rem;
    }    
}